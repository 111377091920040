<template>
  <div class="pa-3">
    <div class="pa-3 my-3 table-weekest-topic">
      <div class="text-h6">Top 5 activities</div>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Action</th>

              <th class="text-center">Count</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in top5Activities" :key="item.action">
              <td>{{ $t("log." + item.action) }}</td>
              <td class="text-center">{{item.count}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <div class="text-h6 pt-3 pb-1">Last 100 activities</div>
    <div class="d-flex align-center justify-space-around">
      <div class="form-content text-center">
        <div v-if="data.length != 0" class="d-flex align-center">
          <div class="flex-grow-1">
            <v-text-field
              dense
              hide-details
              v-model="search"
              label="Search"
              outlined
              clearable
              append-icon="mdi-magnify"
            ></v-text-field>
          </div>
          <div class="px-2 flex-grow-0">
            <v-btn fab small color="primary">
              <v-icon dark> mdi-tune </v-icon>
            </v-btn>
          </div>
        </div>

        <v-data-table
          :headers="headers"
          :items="data"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          class="elevation-0"
          :search="search"
          @page-count="pageCount = $event"
          @click:row="handleClick"
          mobile-breakpoint="0"
        >
          <template v-slot:item.date="{ item }">
            <span>{{
              $moment(item.timestampCreated).format("DD MMM YYYY hh:mm A")
            }}</span>
          </template>
          <template v-slot:item.action="{ item }">
            <span> {{ $t("log." + item.action) }}</span>
          </template>
        </v-data-table>
        <div v-if="data.length != 0" class="text-left pt-3">
          Showing {{ page * 10 - 9 }} to
          {{ page == totalPage ? data.length : page * 10 }} of
          {{ data.length }}
        </div>
        <div v-if="data.length != 0" class="d-flex justify-center pt-3">
          <div class="px-1">
            <v-btn
              @click="goFirstPage()"
              fab
              small
              color="primary"
              :disabled="page == 1"
            >
              <v-icon dark> mdi-page-first </v-icon>
            </v-btn>
          </div>
          <div class="px-1">
            <v-btn
              @click="goPreviousPage()"
              fab
              :disabled="page == 1"
              small
              color="primary"
            >
              <v-icon dark> mdi-chevron-left </v-icon>
            </v-btn>
          </div>
          <div class="px-1">
            <v-menu rounded="lg" offset-y>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  color="primary"
                  rounded
                  class="white--text d-none d-sm-flex"
                  v-bind="attrs"
                  v-on="on"
                  width="120px"
                >
                  Page {{ page }}
                  <v-icon right dark> mdi-chevron-down </v-icon>
                </v-btn>
                <v-btn
                  fab
                  v-bind="attrs"
                  class="d-flex d-sm-none"
                  v-on="on"
                  small
                  color="primary"
                >
                  {{ page }}
                </v-btn>
              </template>
              <v-list style="max-height: 200px" class="overflow-y-auto">
                <v-list-item
                  v-for="p in totalPage"
                  :key="p"
                  link
                  @click="selectPage(p)"
                >
                  <v-list-item-title>Page {{ p }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div class="px-1">
            <v-btn
              @click="goNextPage()"
              fab
              :disabled="page == totalPage"
              small
              color="primary"
            >
              <v-icon dark> mdi-chevron-right </v-icon>
            </v-btn>
          </div>
          <div class="px-1">
            <v-btn
              :disabled="page == totalPage"
              @click="goLastPage()"
              fab
              small
              color="primary"
            >
              <v-icon dark> mdi-page-last </v-icon>
            </v-btn>
          </div>
          <!-- <v-pagination v-model="page" :length="pageCount"  :total-visible="7"></v-pagination> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  props: ["mochiId"],
  computed: {
    size() {
      const size = { xs: "small", sm: "small", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  data: () => ({
    top5Activities: [],
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    activityApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    totalPage: 0,
    search: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    players: [],
    headers: [
      {
        text: "Created",
        align: "start",
        sortable: false,
        value: "date",
      },
      { text: "Action", value: "action" },
    ],
    data: [],
  }),
  created() {
    // for (var i = 0; i < 100; i++) {
    //   this.data.push({
    //     date: moment().subtract(i,'hours').format("DD MMM YYYY hh:mm A") ,
    //     action: i%3 == 0? "student/login":"submission/add",
    //     description: i%3 == 0? "Log in" : "Submit answers",
    //   });
    // }
    this.activityApi.method = "GET";
    this.activityApi.url =
      this.$api.servers.log +
      "/api/v1/" +
      this.$_getLocale() +
      "/activity/player/" +
      this.mochiId;

    this.activityApi.callbackReset = () => {
      this.activityApi.isLoading = true;
      this.activityApi.isError = false;
    };
    this.activityApi.callbackError = (e) => {
      this.activityApi.isLoading = false;
      this.activityApi.isError = true;
      this.activityApi.error = e;
      //BOC:[parent]
      this.callbackError(e);
      //EOC
    };
    this.activityApi.callbackSuccess = (resp) => {
      this.activityApi.isLoading = false;
      this.data = resp;
      this.totalPage = Math.ceil(this.data.length / 10);
      this.getTop5()
    };
  },
  mounted() {
    this.$api.fetch(this.activityApi);
  },
  methods: {
    handleClick(item) {
      this.callbackClick(item);
    },
    getTop5() {
      const actionCounts = this.$_.countBy(this.data, "action");
        
      this.top5Activities = this.$_.chain(actionCounts)
        .toPairs()
        .orderBy([1, 0], ["desc", "asc"])
        .take(5)
        .map(([action, count]) => ({ action, count }))
        .value();
    },
    goNextPage() {
      this.page++;
    },
    goPreviousPage() {
      this.page--;
    },
    selectPage(page) {
      this.page = page;
    },
    goLastPage() {
      this.page = this.totalPage;
    },
    goFirstPage() {
      this.page = 1;
    },
    toRegister() {
      this.$router.push({ name: "PageTestRegister" });
    },
    clickSocial() {
      this.isClickSocial = true;
      this.step = 3;
    },
    decrease() {
      this.step--;
    },
  },
};
</script>
<style scoped>
.table-weekest-topic {
  border: 1px solid black;
}
</style>