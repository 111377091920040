<template>
  <v-btn
    :outlined="outlined"
    color="primary"
    :loading="api.isLoading"
  >
    Last Login:  {{ $moment(lastLogin).format("DD MMM YYYY hh:mm A") }}
  </v-btn>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    "outlined","mochiId"
  ],
  data: () => ({
    lastLogin:null,
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
  }),
  created() {
    this.api.url =
      this.$api.servers.analysis +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/login/last";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.lastLogin = resp[0].timestampUpdated;
    };
    //EOC
  },
  mounted() {
    if(this.mochiId){
    this.api.params = {mochiId: this.mochiId}
    this.$api.fetch(this.api);
    }
  },
  methods: {
    //
  },
};
</script>