<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-row>
        <v-col
          ><h2>{{ (student.name)? student.name : '• • •' }}</h2></v-col
        >
        <v-col cols="12" sm="6">
          <div class="d-flex justify-center justify-sm-end">
            <div class="pr-2">
              <v-btn rounded outlined color="primary" :to="{name:'PageEducatorInstitutionClassroomStudentMove',params:{id:this.$route.params.id,parentId:this.$route.params.parentId,grandparentId:this.$route.params.grandparentId}}"> Move </v-btn>
            </div>
            <div>
              <v-btn rounded color="primary" :to="{name:'PageEducatorInstitutionClassroomStudentEdit',params:{id:this.$route.params.id,parentId:this.$route.params.parentId,grandparentId:this.$route.params.grandparentId}}"> Edit </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-card-title></v-card-title>
      <ALoader :isLoading="api.isLoading"></ALoader>
      <v-tabs
        v-model="tab"
        next-icon="mdi-arrow-right-bold-box-outline"
        prev-icon="mdi-arrow-left-bold-box-outline"
        show-arrows
      >
        <v-tab
          href="#info"
        >
         Info
        </v-tab>
        <v-tab
          href="#player"
        >
         Player
        </v-tab>
        <v-tab
          href="#performance"
        >
        Performance
        </v-tab>
        <v-tab href="#activity"> Activity </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          class="px-1 py-2"
          value="info"
        >
      <Read v-if="!api.isLoading" :headers="headers" :data="student" />
        </v-tab-item>
        <v-tab-item
          class="px-1 py-2"
          value="player"
        >
        <div v-if="mochiId">
          <Read v-if="!playerApi.isLoading" :headers="playerHeaders" :data="player" :callbackChange="generatePassword" :callbackEdit="submitPIN"  />
        </div>
        <div v-if="!mochiId" class="pa-3 text-center">
                No Data
              </div>
        </v-tab-item>
        <v-tab-item
          class="px-1 py-2"
          value="performance"
        >
        <v-tabs centered show-arrows>
        <v-tab>Table</v-tab>
            <v-tab>Chart</v-tab>
            <v-tab-item>
              <div>   
        <Performance v-if="!api.isLoading" :mochiId="mochiId" />
      </div>
            </v-tab-item>
            <v-tab-item>
              <div class="d-flex justify-space-around pt-3" v-if="mochiId" >
                <div v-if="metrics">
                  <v-btn @click="selectChart('chart2')" :outlined="selectedChart!= 'chart2'" color="primary">
                    Mastered topics: {{ metrics.totalMastered }}
                  </v-btn>
                </div>
                <div v-if="metrics">
                  <v-btn @click="selectChart('chart1')" :outlined="selectedChart!= 'chart1'"  color="primary">
                    Total Question: {{ metrics.totalQuestion }}
                  </v-btn>
                </div>
                <div>
                  <v-btn @click="selectChart('chart3')" :outlined="selectedChart!= 'chart3'"  color="primary">
                    <span v-if="metrics && metrics.totalMastered"> Accuracy: {{metrics.accuracy}}%</span>
                    <span v-else> Accuracy: 0%</span>
                  </v-btn>
                </div>
              </div>
              <div v-if="selectedChart== 'chart1'">
                <Chart1 v-if="mochiId" :mochiId="mochiId"></Chart1>
              </div>
              <div  v-if="selectedChart== 'chart2'">
                <Chart2 v-if="mochiId" :mochiId="mochiId"></Chart2>
              </div>
              <div  v-if="selectedChart== 'chart3'">
                <Chart3 v-if="mochiId" :mochiId="mochiId"></Chart3>
              </div>
              <div v-if="!mochiId" class="pa-3 text-center">
                No Data
              </div>
            </v-tab-item>
          </v-tabs>
        </v-tab-item>
        <v-tab-item class="px-1 py-2" value="activity">
          <v-tabs centered show-arrows>
            <v-tab> Table </v-tab>
            <v-tab>Chart</v-tab>

            <v-tab-item>
              <div>
                <Table v-if="mochiId" :mochiId="mochiId"/>
              </div>
              <div v-if="!mochiId" class="pa-3 text-center">
                No Activity
              </div>
            </v-tab-item>
            <v-tab-item>
              <div class="d-flex justify-space-around pt-3">
                <div @click="selectActivityChart('chart4')">
                  <LastLogin v-if="mochiId" :outlined="selectedActivityChart!= 'chart4'" :mochiId="mochiId"></LastLogin>
                </div>
                <div>
                  <v-btn v-if="mochiId"  @click="selectActivityChart('chart5')" :outlined="selectedActivityChart!= 'chart5'"  color="primary">
                    Total Hours Spent : 310
                  </v-btn>
                </div>
              </div>
              <div v-if="selectedActivityChart== 'chart4'">
                <Chart4 v-if="mochiId" :mochiId="mochiId"></Chart4>
              </div>
              <div  v-if="selectedActivityChart== 'chart5'">
                <Chart5 v-if="mochiId" ></Chart5>
              </div>
              <div v-if="!mochiId" class="pa-3 text-center">
                No Activity
              </div>
            </v-tab-item>
          </v-tabs>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
import Read from "@/components/Datatable/Read.vue";
import Performance from "@/components/Datatable/Performance.vue";

import Chart1 from "@/components/Performance/Chart1.vue";
import Chart2 from "@/components/Performance/Chart2.vue";
import Chart3 from "@/components/Performance/Chart3.vue";
import Chart4 from "@/components/Activity/Chart4.vue";
import Chart5 from "@/components/Activity/Chart5.vue";
import Table from "@/components/Activity/Table.vue";
import LastLogin from "@/components/Activity/Metrics/LastLogin.vue";
export default {
  components: {
    Read,
    Performance,
    Chart1,
    Chart2,
    Chart3,
    Chart4,
    Chart5,
    Table,
    LastLogin
    //
  },
  computed: {
    size() {
      const size = { xs: "small", sm: "small", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  // computed: mapState({
  //     auth: (state) => state.auth.data,
  //   }),
  props: [
    //
  ],
  data: () => ({
    metrics:{},
    mochiId: null,
    tab:null,
    selectedChart: 'chart2',
    selectedActivityChart:'chart4',
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    student:{},
    player:{},
 //BOC:[api]
 api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    playerApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    passwordApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    metricsApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    headers: [
      // {
      //   text: "ID",
      //   align: "start",
      //   sortable: false,
      //   value: "id",
      // },
      { text: "Name", value: "name" },
      // { text: "Mochi ID", value: "mochiId" },
      { text: "Last Update", value: "timestampUpdated" },
    ],
    playerHeaders: [
      // {
      //   text: "ID",
      //   align: "start",
      //   sortable: false,
      //   value: "id",
      // },
      { text: "Avatar", value: "avatar" },
      { text: "Mochi ID", value: "mochiId" },
      { text: "Enable PIN", value: "enablePIN" },
      { text: "PIN", value: "PIN" },
     { text: "Last Update", value: "timestampUpdated" },
    ],
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs = [
      {
        text: "Dashboard",
        to: { name: "PageEducatorDashboard" },
        exact: true,
      },
      {
        text: "My Institution",
        to: {
          name: "PageEducatorInstitutionDashboard",
          params: { id: this.$route.params.grandparentId },
        },
        exact: true,
      },
      {
        text: "Classrooms",
        to: {
          name: "PageEducatorInstitutionClassroom",
          params: { id: this.$route.params.grandparentId },
        },
        exact: true,
      },
      {
        text: "Classroom",
        to: {
          name: "PageEducatorInstitutionClassroomDashboard",
          params:{
            id:this.$route.params.parentId,
            parentId:this.$route.params.grandparentId,
          },
        },
        exact: true,
      },
      {
        text: "Students",
        to: { 
          name: "PageEducatorInstitutionClassroomStudent",
          params:{
            id:this.$route.params.parentId,
            parentId:this.$route.params.grandparentId,
          },
        },
        exact: true,
      },
      {
        text: "Student",
        to: { 
          name: "PageEducatorInstitutionClassroomStudentRead",
          params:{
            id:this.$route.params.id,
            parentId:this.$route.params.parentId,
            grandparentId:this.$route.params.grandparentId
          }
        },
        exact: true,
      },
    ];
    //EOC
    
    this.api.method = "GET";
    this.api.url =
    this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/"+this.$route.params.grandparentId+"/classroom/" +
      this.$route.params.parentId +
      "/student/" +
      this.$route.params.id;

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.student = resp;
      this.mochiId = this.student.mochiId || null
      // this.updateBreadcrumb(resp)
      if(this.mochiId){
        this.getPlayer(this.mochiId)
      }
      this.getPerformanceMetrics()
    };

   

    this.passwordApi.callbackReset = () => {
      this.passwordApi.isLoading = true;
      this.passwordApi.isError = false;
    };

    this.passwordApi.callbackError = (e) => {
      this.passwordApi.isLoading = false;
      this.passwordApi.isError = true;
      this.passwordApi.error = e;
    };

    this.passwordApi.callbackSuccess = (resp) => {
      this.passwordApi.isLoading = false;
      this.player = resp;
      console.log(this.player)
     // this.api.isLoading = false;
      // this.updateBreadcrumb(resp)
     // this.getPerformanceMetrics()
    };
  },
  mounted() {
    this.$api.fetch(this.api);
  },
  methods: {
    // updateBreadcrumb(resp) {
    //   this.breadcrumbs.push({
    //     text: "Dashboard",
    //     to: { name: "PageEducatorDashboard" },
    //     exact: true,
    //   });
    //   this.breadcrumbs.push({
    //     text: resp.Classroom.Institution.name,
    //     to: { name: "PageEducatorInstitutionDashboard",params:{id:this.$route.params.grandparentId} },
    //     exact: true,
    //   });
    //   this.breadcrumbs.push({
    //     text: 'Classrooms',
    //     to: { name: "PageEducatorInstitutionClassroom",params:{id:this.$route.params.grandparentId} },
    //     exact: true,
    //   });
    //   this.breadcrumbs.push({
    //     text: resp.Classroom.name,
    //     to: { name: "PageEducatorInstitutionClassroomDashboard" ,params:{id:this.$route.params.parentId,parentId:this.$route.params.grandparentId}},
    //     exact: true,
    //   });

    //   this.breadcrumbs.push({
    //   text: "Students",
    //   to: { name: "PageEducatorInstitutionClassroomStudent" ,params:{id:this.$route.params.parentId,parentId:this.$route.params.grandparentId} },
    //   exact: true,
    // });
    // this.breadcrumbs.push({
    //   text: resp.name,
    //   to: { name: "PageEducatorInstitutionClassroomStudentRead" ,params:{id:this.$route.params.id,parentId:this.$route.params.parentId,grandparentId:this.$route.params.grandparentId} },
    //   exact: true,
    // });
    // },
    selectChart(value){
      this.selectedChart = value
    },
    generatePassword(value){
      this.passwordApi.url =
    this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/player/generatePIN";
    //  this.api.isLoading = true;
      var form = {mochiId:value.mochiId, isEnabledPIN: value.isEnabledPIN}
      this.passwordApi.params = this.$_.clone(form)
      this.$api.fetch(this.passwordApi)
    },
    submitPIN(value){
      this.passwordApi.url =
    this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/player/editPIN";
    //  this.api.isLoading = true;
      var form = {mochiId:value.mochiId, PIN: value.PIN}
      this.passwordApi.params = this.$_.clone(form)
      this.$api.fetch(this.passwordApi)
    },
    selectActivityChart(value){
      this.selectedActivityChart = value
    },
    getPlayer(mochiId){
      this.playerApi.method = "GET";
    this.playerApi.url =
    this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/player/"+mochiId;

    this.playerApi.callbackReset = () => {
      this.playerApi.isLoading = true;
      this.playerApi.isError = false;
    };

    this.playerApi.callbackError = (e) => {
      this.playerApi.isLoading = false;
      this.playerApi.isError = true;
      this.playerApi.error = e;
    };

    this.playerApi.callbackSuccess = (resp) => {
      this.playerApi.isLoading = false;
      this.player = resp;
    };

    this.$api.fetch(this.playerApi);
    },
    getPerformanceMetrics(){
      this.metricsApi.url =
      this.$api.servers.log +
      "/api/v1/" +
      this.$_getLocale() +
      "/performance/player/" +
      this.mochiId +"/metrics";

    this.metricsApi.callbackReset = () => {
      this.metricsApi.isLoading = true;
      this.metricsApi.isError = false;
    };
    this.metricsApi.callbackError = (e) => {
      this.metricsApi.isLoading = false;
      this.metricsApi.isError = true;
      this.metricsApi.error = e;
     
    };
    this.metricsApi.callbackSuccess = (resp) => {
      this.metricsApi.isLoading = false;
      this.metrics = resp;
    };
    this.$api.fetch(this.metricsApi);
    }
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 600px !important;
}
</style>