<template>
  <div class="ma-3">
    <VueApexCharts
      v-if="series[0].data && series[0].data.length != 0"
      height="420px"
      type="line"
      :options="options"
      :series="series"
    ></VueApexCharts>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import datasetMonths from "@/datasets/month";
import { mapState } from "vuex";
import moment from "moment";
export default {
  components: {
    VueApexCharts,
  },
  computed: mapState({
    //
  }),
  props: ["mochiId"],
  data: () => ({
    data: [],
    months: datasetMonths,
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    options: {
      chart: {
        id: "vuechart-example3",
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#008FFB"],
      title: {
        text: "Total Login",
      },
      xaxis: {
        labels: {
          style: {
            fontSize: "10px",
          },
        },
        categories: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
      },
      yaxis: {
        min: 0,
        max : null,
      }
    },
    series: [
      {
        name: "Total Login",
        data: [],
      },
    ],
    value: [],
  }),
  created() {
    this.api.url =
      this.$api.servers.analysis +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/login/total";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      this.getValue();
    };
    //EOC
  },
  mounted() {
    if (this.mochiId) {
      this.api.params = { mochiId: this.mochiId };
      this.$api.fetch(this.api);
    }
  },
  methods: {
    getValue() {
      const C = this.$_.map(this.months, (a) => {
        const objB = this.$_.find(this.data, { month: a.id });
        var length;
        if (objB) {
          length = JSON.parse(objB.data).length;
        } else {
          if (parseInt(moment().format("M")) > a.id) {
            length = 0;
          } else {
            length = null;
          }
        }
        return {
          month: a.id,
          length: length,
        };
      });
      var data = this.$_.map(C, "length");
      var filterData = this.$_.compact(data)
     this.options.yaxis.max =  this.$_.max(filterData)< 10 ? 10 : this.$_.max(filterData)
      this.series[0].data = data
    },
  },
};
</script>