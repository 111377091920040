<template>
  <div class="ma-3">
    <VueApexCharts
    v-if="series[0].data && series[0].data.length != 0"
      height="420px"
      type="line"
      :options="options"
      :series="series"
    ></VueApexCharts>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { mapState } from "vuex";
import datasetMonths from "@/datasets/month";
export default {
  components: {
    VueApexCharts,
  },
  computed: mapState({
    //
  }),
  props: [
    'mochiId'
  ],
  data: () => ({
    months: datasetMonths,
    mastered:[],
    questions:[],
    data:[],
     //BOC:[api]
    apiQuestion: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    options: {
      chart: {
        id: "vuechart-example3",
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#008FFB"],
      title: {
        text: "Accuracy (%)",
      },
      xaxis: {
        labels: {
          style: {
            fontSize: "10px",
          },
        },
        categories: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October","November","December"],
      },
      yaxis: {
        min: 0,
      max: 100,

      }
    },
    series: [
      {
        name: "Accuracy",
        data: [],
      },
    ],
    
  }),
  created() {

     this.apiQuestion.url =
      this.$api.servers.log +
      "/api/v1/" +
      this.$_getLocale() +
      "/performance/player/"+this.mochiId+"/totalQuestions";

    this.apiQuestion.callbackReset = () => {
      this.apiQuestion.isLoading = true;
      this.apiQuestion.isError = false;
    };

    this.apiQuestion.callbackError = (e) => {
      this.apiQuestion.isLoading = false;
      this.apiQuestion.isError = true;
      this.apiQuestion.error = e;
    };

    this.apiQuestion.callbackSuccess = (resp) => {
      this.apiQuestion.isLoading = false;
      this.questions =  resp;
      this.getAccuracy()
    };
  },
  mounted() {
    if (this.mochiId) {
      this.$api.fetch(this.apiQuestion);
    }
  },
  methods: {
    getAccuracy(){
      var self = this
//       const C = self.$_.map(self.months, month => {
//   const monthData = self.$_.filter(self.questions, item => {
//     const monthNum = this.$moment(item.timestampUpdated).month() + 1;
//     return monthNum === month.id;
//   });

//   const totalQuestion = self.$_.reduce(monthData, (sum, item) => {
//     return sum + item.totalQuestion;
//   }, 0);

//   const totalCorrect = self.$_.reduce(monthData, (sum, item) => {
//     return sum + item.totalCorrect;
//   }, 0);

//   const accuracy = totalCorrect / totalQuestion;

//   return {
//     id: month.id,
//     name: month.name,
//     totalQuestion,
//     totalCorrect,
//     accuracy: self.$_.isNaN(accuracy) ? 0 : accuracy.toFixed(2)*100,
//   };
// });

// Step 1: Group array A by month
const groupedA = self.$_.groupBy(this.questions, a => self.$moment(a.timestampUpdated).month() + 1); // month starts at 0

// Step 2: Calculate total question, total correct, and accuracy for each month
const C = self.months.map((month, index) => {
  const monthData = groupedA[index + 1] || []; // month starts at 1
  const totalQuestion = self.$_.sumBy(monthData, 'totalQuestion');
  const totalCorrect = self.$_.sumBy(monthData, 'totalCorrect');
  const accuracy = totalQuestion === 0 ? 0 : totalCorrect / totalQuestion;

  return {
    ...month,
    totalQuestion,
    totalCorrect,
    accuracy,
  };
});

// Step 3: Calculate array D
const data = self.$_.map(C, (item, index) => {
  const totalCorrect = self.$_.sumBy(self.$_.slice(C, 0, index + 1), 'totalCorrect');
  const totalQuestion = self.$_.sumBy(self.$_.slice(C, 0, index + 1), 'totalQuestion');
  const accuracy = totalQuestion > 0 ? totalCorrect / totalQuestion : 0;
  return self.$_.isNaN(accuracy) ? 0 : accuracy.toFixed(2)*100;
});

this.series[0].data = data
    }

  },
};
</script>