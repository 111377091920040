<template>
  <div class="ma-3">
    <VueApexCharts
      height="420px"
      type="line"
      :options="options"
      :series="series"
    ></VueApexCharts>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { mapState } from "vuex";
import datasetMonths from "@/datasets/month";
export default {
  components: {
    VueApexCharts,
  },
  computed: mapState({
    //
  }),
  props: ["mochiId"],
  data: () => ({
    data: [],
    months: datasetMonths,
      //BOC:[api]
      api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    options: {
      chart: {
        id: "vuechart-example3",
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#008FFB"],
      title: {
        text: "Total Hours Spent",
      },
      xaxis: {
        labels: {
          style: {
            fontSize: "10px",
          },
        },
        categories: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October","November","December"],
      },
    },
    series: [
      {
        name: "Total Hours Spent",
        data: [90, 80, 55, 80, 77, 60, 55, 40,80,93,55,60],
      },
    ],
    
  }),
  created() {
    this.api.url =
      this.$api.servers.log +
      "/api/v1/" +
      this.$_getLocale() +
      "/submission/player/"+this.mochiId+"/totalTime";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      this.getValue();
    };
    //EOC
  },
  mounted() {
   
    if (this.mochiId) {
      alert('hiiii')
      this.$api.fetch(this.api);
    }
  },
  methods: {
    getValue() {
      console.log(this.data)
    }
  },
};
</script>