var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3"},[_c('div',{staticClass:"pa-3 my-3 table-weekest-topic"},[_c('div',{staticClass:"text-h6"},[_vm._v("Top 5 activities")]),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Action")]),_c('th',{staticClass:"text-center"},[_vm._v("Count")])])]),_c('tbody',_vm._l((_vm.top5Activities),function(item){return _c('tr',{key:item.action},[_c('td',[_vm._v(_vm._s(_vm.$t("log." + item.action)))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.count))])])}),0)]},proxy:true}])})],1),_c('div',{staticClass:"text-h6 pt-3 pb-1"},[_vm._v("Last 100 activities")]),_c('div',{staticClass:"d-flex align-center justify-space-around"},[_c('div',{staticClass:"form-content text-center"},[(_vm.data.length != 0)?_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"flex-grow-1"},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","label":"Search","outlined":"","clearable":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"px-2 flex-grow-0"},[_c('v-btn',{attrs:{"fab":"","small":"","color":"primary"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-tune ")])],1)],1)]):_vm._e(),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.data,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","search":_vm.search,"mobile-breakpoint":"0"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.timestampCreated).format("DD MMM YYYY hh:mm A")))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t("log." + item.action)))])]}}])}),(_vm.data.length != 0)?_c('div',{staticClass:"text-left pt-3"},[_vm._v(" Showing "+_vm._s(_vm.page * 10 - 9)+" to "+_vm._s(_vm.page == _vm.totalPage ? _vm.data.length : _vm.page * 10)+" of "+_vm._s(_vm.data.length)+" ")]):_vm._e(),(_vm.data.length != 0)?_c('div',{staticClass:"d-flex justify-center pt-3"},[_c('div',{staticClass:"px-1"},[_c('v-btn',{attrs:{"fab":"","small":"","color":"primary","disabled":_vm.page == 1},on:{"click":function($event){return _vm.goFirstPage()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-page-first ")])],1)],1),_c('div',{staticClass:"px-1"},[_c('v-btn',{attrs:{"fab":"","disabled":_vm.page == 1,"small":"","color":"primary"},on:{"click":function($event){return _vm.goPreviousPage()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-chevron-left ")])],1)],1),_c('div',{staticClass:"px-1"},[_c('v-menu',{attrs:{"rounded":"lg","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text d-none d-sm-flex",attrs:{"color":"primary","rounded":"","width":"120px"}},'v-btn',attrs,false),on),[_vm._v(" Page "+_vm._s(_vm.page)+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-chevron-down ")])],1),_c('v-btn',_vm._g(_vm._b({staticClass:"d-flex d-sm-none",attrs:{"fab":"","small":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.page)+" ")])]}}],null,false,2818044819)},[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"200px"}},_vm._l((_vm.totalPage),function(p){return _c('v-list-item',{key:p,attrs:{"link":""},on:{"click":function($event){return _vm.selectPage(p)}}},[_c('v-list-item-title',[_vm._v("Page "+_vm._s(p))])],1)}),1)],1)],1),_c('div',{staticClass:"px-1"},[_c('v-btn',{attrs:{"fab":"","disabled":_vm.page == _vm.totalPage,"small":"","color":"primary"},on:{"click":function($event){return _vm.goNextPage()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-chevron-right ")])],1)],1),_c('div',{staticClass:"px-1"},[_c('v-btn',{attrs:{"disabled":_vm.page == _vm.totalPage,"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.goLastPage()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-page-last ")])],1)],1)]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }