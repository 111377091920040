<template>
  <div class="ma-3">
    <VueApexCharts
    v-if="series[0].data && series[0].data.length != 0"
      height="420px"
      type="line"
      :options="options"
      :series="series"
    ></VueApexCharts>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { mapState } from "vuex";
import datasetMonths from "@/datasets/month";
export default {
  components: {
    VueApexCharts,
  },
  computed: mapState({
    //
  }),
  props: [
    'mochiId'
  ],
  data: () => ({
    months: datasetMonths,
     //BOC:[api]
     api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    options: {
      chart: {
        id: "vuechart-example2",
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#008FFB"],
      title: {
        text: "Mastered topics",
      },
      xaxis: {
        labels: {
          style: {
            fontSize: "10px",
          },
        },
        categories: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October","November","December"],
      },
      yaxis: {
        min: 0,
        max : null,
      }
    },
    series: [
      {
        name: "Mastered topics",
        data: [],
      },
    ],
    
  }),
  created() {
    this.api.url =
      this.$api.servers.log +
      "/api/v1/" +
      this.$_getLocale() +
      "/performance/player/"+this.mochiId+"/masteredQuestions";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      this.getValue();
    };
    //EOC
  },
  mounted() {
    if (this.mochiId) {
      this.$api.fetch(this.api);
    }
  },
  methods: {
    getValue(){
      const months = this.$_.map(this.months, 'name');
      const monthObj = this.$_.zipObject(months, this.$_.fill(Array(months.length), 0));
      const monthCount = this.$_.countBy(this.data, (item) => this.$moment(item.timestampUpdated).format('MMMM'));
      const C = this.$_.map(monthObj, (value, key) => ({ month: key, total: monthCount[key] || 0 }));
      const data = this.$_.reduce(
  C,
  (acc, item, index) => {
    if (index === 0) {
      acc.push(item.total);
    } else {
      acc.push(acc[index - 1] + item.total);
    }
    return acc;
  },
  []
);
this.options.yaxis.max =  this.$_.max(data)< 10 ? 10 : this.$_.max(data)
this.series[0].data = data
    }
  },
};
</script>