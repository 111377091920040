<template>
  <v-container>
    <!-- BOC:[error] -->
    <AError :api="submissionApi"></AError>
    <!-- EOC -->
    <v-card
      :loading="submissionApi.isLoading"
      class="mx-auto performance"
      elevation="0"
    >
      <v-card-text>
        <DropdownSyllabus
          ref="dropdown_syllabus"
          :callbackSelect="selectSyllabus"
        ></DropdownSyllabus>
        <v-card class="my-3">
          <v-card-title>Weakest Topics</v-card-title>
          <v-card-subtitle>Revise these topics with the student may help</v-card-subtitle>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Topics</th>
                  <th class="text-center">Accuracy</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in weakestQuestionsFilter" :key="item.id">
                  <td>
                    <span class="font-weight-bold">Chapter {{ item.chapter_sort }}:</span> {{ item.name }}
                  </td>
                  <td class="text-center">
                   {{
                          Math.round(
                            ( item.accuracy *
                              100 +
                              Number.EPSILON) *
                              100
                          ) / 100
                        }}%
                  </td>
                </tr>
                <tr v-if="weakestQuestionsFilter.length == 0 && !weakestQuestionApi.isLoading">
                  <td class="text-center" colspan="2">
                    <span >No Items</span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <v-card class="my-3">
          <v-simple-table>
            <v-list>
              <div>
                <div class="py-1 px-3 table-header-cell text-left">
                  <span class="chapter-name">Chapter</span>
                </div>
                <div class="py-1 px-3 table-header-cell">
                  Submissions
                </div>
                <div class="py-1 px-3 table-header-cell">
                  Stars
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" small v-on="on">
                        mdi-alert-circle
                      </v-icon>
                    </template>
                    <span
                      >0 Star:
                      Less than 60%
                      Accuracy <br />
                      1 Stars: >60%
                      Accuracy <br />
                      2 Stars: > 80%
                      Accuracy <br />
                      3 Stars: >= 100%
                      Accuracy</span
                    >
                  </v-tooltip>
                </div>
                <!-- 
                <div class="py-1 px-3 table-header-cell">
                  {{ $t("model.prop.score") }}
                </div> -->
                <div class="py-1 px-3 table-header-cell">
                  Corrects
                </div>
                <div class="py-1 px-3 table-header-cell">
                  Accuracy
                </div>
                <!-- <div class="py-1 px-3 table-header-cell">
                  {{ $t("model.prop.time") }}
                </div> -->
                <div class="py-1 px-3 table-header-cell"></div>
              </div>
              <v-list-group
                v-for="(item, indexc) in chapterWithRecord"
                :v-model="item.key == chapterKeyShow ? true : false"
                :key="indexc"
                no-action
              >
                <template v-slot:activator>
                  <v-list-item-content @click="showChapter(item.key)">
                    <div class="resp-table-row">
                      <div class="table-body-cell-main text-left">
                        <span class="chapter-name">
                          {{ item.chapter_sort }}.
                          {{ item.name }}
                        </span>
                      </div>
                      <div class="py-1 px-3 table-body-cell-main" v-if="item.Record">
                        <span v-if="item.Record.attempt">
                          {{ item.Record.attempt }}</span
                        >
                        <span v-else>-</span>
                      </div>
                      <div class="py-1 px-3 table-body-cell-main" v-else>0</div>
                      <div class="py-1 px-3 table-body-cell-main" v-if="item.Record">
                        {{ item.Record.star }}/{{
                          item.Question.length * 3
                        }}
                        ★
                      </div>
                      <div class="py-1 px-3 table-body-cell-main" v-else>
                        0/{{ item.Question.length * 3 }} ★
                      </div>
                      <!-- 
                      <div class="py-1 px-3 table-body-cell-main" v-else>0 ★</div>
                      <div class="py-1 px-3 table-body-cell-main" v-if="item.Record">
                        {{ item.Record.totalScore }}
                      </div>
                      <div class="py-1 px-3 table-body-cell-main" v-else>0</div> -->
                      <!-- <div class="py-1 px-3 table-body-cell-main">-</div> -->
                      <div v-if="item.Record" class="py-1 px-3 table-body-cell-main">
                        {{ item.Record.totalCorrect }}/{{
                          item.Record.totalQuestion
                        }}
                      </div>
                      <div class="py-1 px-3 table-body-cell-main" v-else>0</div>
                      <div v-if="item.Record" class="py-1 px-3 table-body-cell-main">
                        {{
                          Math.round(
                            ((item.Record.totalCorrect /
                              item.Record.totalQuestion) *
                              100 +
                              Number.EPSILON) *
                              100
                          ) / 100
                        }}%
                      </div>
                      <div class="py-1 px-3 table-body-cell-main" v-else>0%</div>
                      <div class="py-1 px-3 table-body-cell-main">
                        <span
                          ><v-icon v-if="chapterKeyShow == item.key"
                            >mdi-menu-up</v-icon
                          >
                          <v-icon v-else>mdi-menu-down</v-icon>
                        </span>
                      </div>
                    </div>
                  </v-list-item-content>
                </template>

                <v-list-group
                  v-for="(question, index) in item.Question"
                  :key="index"
                  sub-group
                  class="blue lighten-5"
                >
                  <template v-slot:activator>
                    <v-list-item-content
                      @click="showChapterQuestion(question.key)"
                    >
                      <div class="resp-table-row">
                        <div class="py-3 table-body-cell-main text-left">
                          <span class="question-name">
                            {{ item.chapter_sort }} .{{ index + 1 }}.
                            {{
                              question.name
                            }}
                          </span>
                        </div>
                        <div
                          class="py-1 px-3 table-body-cell-main"
                          v-if="question.Record"
                        >
                          <span v-if="question.Submission">
                            {{ question.Submission.length }}</span
                          >
                          <span v-else>-</span>
                        </div>
                        <div class="py-1 px-3 table-body-cell-main" v-else>0</div>
                        <div
                          class="py-1 px-3 table-body-cell-main"
                          v-if="question.Record"
                        >
                          <span
                            v-if="
                              (question.Record.totalCorrect /
                                question.Record.totalQuestion) *
                                100 <
                              60
                            "
                          >
                            0 ★</span
                          >
                          <span
                            v-else-if="
                              (question.Record.totalCorrect /
                                question.Record.totalQuestion) *
                                100 >=
                                60 &&
                              (question.Record.totalCorrect /
                                question.Record.totalQuestion) *
                                100 <
                                80
                            "
                          >
                            1 ★</span
                          >
                          <span
                            v-else-if="
                              (question.Record.totalCorrect /
                                question.Record.totalQuestion) *
                                100 >=
                                80 &&
                              (question.Record.totalCorrect /
                                question.Record.totalQuestion) *
                                100 <
                                100
                            "
                          >
                            2 ★</span
                          >
                          <span
                            v-else-if="
                              (question.Record.totalCorrect /
                                question.Record.totalQuestion) *
                                100 >=
                              100
                            "
                          >
                            3 ★</span
                          >
                        </div>
                        <div class="py-1 px-3 table-body-cell-main" v-else>0 ★</div>
                        <!-- 
                        <div
                          class="py-1 px-3 table-body-cell-main"
                          v-if="question.Question.Record"
                        >
                          {{ question.Question.Record.totalScore }}
                        </div>
                        <div class="py-1 px-3 table-body-cell-main" v-else>0</div> -->
                        <div
                          class="py-1 px-3 table-body-cell-main"
                          v-if="
                            question.Record &&
                            question.Record.totalCorrect
                          "
                        >
                          {{ question.Record.totalCorrect }}/{{
                            question.Record.totalQuestion
                          }}
                        </div>
                        <div class="py-1 px-3 table-body-cell-main" v-else>0</div>
                        <div
                          class="py-1 px-3 table-body-cell-main"
                          v-if="
                            question.Record &&
                            question.Record.totalCorrect
                          "
                        >
                          {{
                            Math.round(
                              ((question.Record.totalCorrect /
                                question.Record.totalQuestion) *
                                100 +
                                Number.EPSILON) *
                                100
                            ) / 100
                          }}%
                        </div>
                        <div class="py-1 px-3 table-body-cell-main" v-else>0%</div>
                        <!-- <div
                          class="py-1 px-3 table-body-cell-main"
                          v-if="question.Question.Record && question.Question.Record.countCorrect"
                        >
                          {{ question.Question.Record.time }}
                        </div>
                        <div class="py-1 px-3 table-body-cell-main" v-else>0</div> -->
                        <div class="py-1 px-3 table-body-cell-main">
                          <span
                            ><v-icon
                              v-if="
                                questionKeyShow.includes(question.key)
                              "
                              >mdi-menu-up</v-icon
                            >
                            <v-icon v-else>mdi-menu-down</v-icon>
                          </span>
                        </div>
                      </div>
                    </v-list-item-content>
                  </template>
                  <div v-if="question.Submission">
                    <div
                      v-for="(submissionItem, index) in question
                        .Submission"
                      :key="index"
                    >
                      <v-list-item link class="teal lighten-4">
                        <div class="resp-table-row">
                          <div class="table-body-cell-main text-left">
                            <span class="submission-name">
                              {{
                                $moment(submissionItem.timestampCreated).format(
                                  "DD/MM/YYYY hh:mm a"
                                )
                              }}
                            </span>
                          </div>
                          <div class="py-1 px-3 table-body-cell-main">
                            {{ question.Submission.length - index }}
                          </div>
                          <div class="py-1 px-3 table-body-cell-main">
                            <span v-if="submissionItem.accuracy * 100 < 60">
                              0 ★</span
                            >
                            <span
                              v-else-if="
                                submissionItem.accuracy * 100 >= 60 &&
                                submissionItem.accuracy * 100 < 80
                              "
                            >
                              1 ★</span
                            >
                            <span
                              v-else-if="
                                submissionItem.accuracy * 100 >= 80 &&
                                submissionItem.accuracy * 100 < 100
                              "
                            >
                              2 ★</span
                            >
                            <span
                              v-else-if="submissionItem.accuracy * 100 >= 100"
                            >
                              3 ★</span
                            >
                          </div>
                          <!-- 
                        <div class="py-1 px-3 table-body-cell-main">
                          {{ submissionItem.score }}
                        </div> -->
                          <div class="py-1 px-3 table-body-cell-main">
                            {{ submissionItem.countCorrect }}/{{
                              submissionItem.countQuestion
                            }}
                          </div>
                          <div class="py-1 px-3 table-body-cell-main">
                            {{ submissionItem.accuracy * 100 }}%
                          </div>
                          <!-- <div class="py-1 px-3 table-body-cell-main">
                          {{ submissionItem.time }}
                        </div> -->
                          <div class="py-1 px-3 table-body-cell-main"></div>
                        </div>
                      </v-list-item>
                    </div>
                  </div>
                  <div v-else>
                    <v-list-item class="teal lighten-4">
                      {{ $t("view.PagePerformance.no_items") }}
                    </v-list-item>
                  </div>
                  <div
                    v-if="
                      question.Submission &&
                      !question.Submission.some(
                        (item) => item.questionKey === question.key
                      )
                    "
                  >
                    <v-list-item class="teal lighten-4">
                      {{ $t("view.PagePerformance.no_items") }}
                    </v-list-item>
                  </div>
                </v-list-group>
              </v-list-group>
            </v-list>
          </v-simple-table>
        </v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import DropdownSyllabus from "../DropdownSyllabusV2.vue";
export default {
  components: {
    DropdownSyllabus,
    //
  },
  computed: mapState({
    //
  }),
  props: ["mochiId"],
  data: () => ({
    syllabus: {},
    chapters: [],
    records: [],
    recordsByChapter: [],
    recordsByQuestion: [],
    chapterWithRecord: [],
    questionWithRecord: [],
    submission: [],
    submissionByQuestion: [],
    chapterKey: null,
    questionKey: null,
    questionIndex: 0,
    chapterKeyShow: null,
    questionKeyShow: [],
    weakestQuestions:[],
    weakestQuestionsFilter:[],
    questions:[],
    //BOC:[api]
    submissionApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //
    //BOC:[api]
    playerApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
     //BOC:[api]
     weakestQuestionApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    chapterApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
     //BOC:[api]
     syllabiQuestionApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    isShowQuestion: false,
    data: [],
  }),
  created() {
    //BOC:[api]
    this.playerApi.url =
      this.$api.servers.log +
      "/api/v1/" +
      this.$_getLocale() +
      "/performance/player/" +
      this.mochiId;

    this.playerApi.callbackReset = () => {
      this.playerApi.isLoading = true;
      this.playerApi.isError = false;
    };
    this.playerApi.callbackError = (e) => {
      this.playerApi.isLoading = false;
      this.playerApi.isError = true;
      this.playerApi.error = e;
      //BOC:[parent]
      this.callbackError(e);
      //EOC
    };
    this.playerApi.callbackSuccess = (resp) => {
      this.playerApi.isLoading = false;
      this.records = resp.Performance;
      this.getSyllabiQuestion();
      // const pluck = (arr, key) => arr.map((i) => i[key]);
      // this.records = pluck(this.data, "Record");
    };

    this.weakestQuestionApi.url =
      this.$api.servers.log +
      "/api/v1/" +
      this.$_getLocale() +
      "/performance/player/" +
      this.mochiId+"/weakestQuestions";

    this.weakestQuestionApi.callbackReset = () => {
      this.weakestQuestionApi.isLoading = true;
      this.weakestQuestionApi.isError = false;
    };
    this.weakestQuestionApi.callbackError = (e) => {
      this.weakestQuestionApi.isLoading = false;
      this.weakestQuestionApi.isError = true;
      this.weakestQuestionApi.error = e;
    };
    this.weakestQuestionApi.callbackSuccess = (resp) => {
      this.weakestQuestionApi.isLoading = false;
      this.weakestQuestions = resp;
      this.getSyllabiQuestion()
    };

    //EOC
  },
  beforeMount() {},
  mounted() {
    this.$refs["dropdown_syllabus"].fetch();
  },
  methods: {
    selectSyllabus(item) {
      this.syllabus = item;
      this.playerApi.params = { syllabusKey: item.key };
      this.weakestQuestionApi.params = { syllabusKey: item.key };
      if (this.mochiId) {
        this.$api.fetch(this.playerApi);
        this.$api.fetch(this.weakestQuestionApi);
      } else {
        (this.records = []), this.getSyllabiQuestion();
      }
    },
    showChapter(key) {
      if (key == this.chapterKeyShow) {
        this.chapterKeyShow = null;
      } else {
        this.chapterKeyShow = key;
      }
    },
    showChapterQuestion(key) {
      if (this.questionKeyShow.includes(key)) {
        var index = this.questionKeyShow.indexOf(key);
        this.questionKeyShow.splice(index, 1);
      } else {
        this.questionKeyShow.push(key);
      }
    },
    getChaptrs() {
      this.chapterApi.method = "GET";
      this.chapterApi.url =
      this.$api.servers.question + "/v2/syllabi/" +
        this.syllabus.key +
        "/chapters?lang=en";

      this.chapterApi.callbackReset = () => {
        this.chapterApi.isLoading = true;
        this.chapterApi.isError = false;
      };
      this.chapterApi.callbackError = (e) => {
        this.chapterApi.isLoading = false;
        this.chapterApi.isError = true;
        this.chapterApi.error = e;
        //BOC:[parent]
        this.callbackError(e);
        //EOC
      };
      this.chapterApi.callbackSuccess = (resp) => {
        this.chapterApi.isLoading = false;
        this.chapters = resp;

        this.joinChapterQuestion()
        // this.getSubmission();
        // this.filterSubmission()
        // const pluck = (arr, key) => arr.map((i) => i[key]);
        // this.records = pluck(this.data, "Record");
      };
      
      this.$api.fetch(this.chapterApi);
    },
    getSyllabiQuestion() {
      this.syllabiQuestionApi.method = "GET";
      this.syllabiQuestionApi.url =
      this.$api.servers.question + "/v2/syllabi/" +
        this.syllabus.key +
        "/questions?lang=en";

      this.syllabiQuestionApi.callbackReset = () => {
        this.syllabiQuestionApi.isLoading = true;
        this.syllabiQuestionApi.isError = false;
      };
      this.syllabiQuestionApi.callbackError = (e) => {
        this.syllabiQuestionApi.isLoading = false;
        this.syllabiQuestionApi.isError = true;
        this.syllabiQuestionApi.error = e;
      };
      this.syllabiQuestionApi.callbackSuccess = (resp) => {
        this.syllabiQuestionApi.isLoading = false;
        this.questions = resp
        this.getWeakestQuestions(resp);
        this.getChaptrs();
      };
      this.$api.fetch(this.syllabiQuestionApi);
    },
    joinChapterQuestion(){
      
      var self = this
      const chapterWithRecord = self.$_.map(self.$_.groupBy(self.questions, item => item.key.substring(0, 9)), (questions, chapterKey) => {
  const chapter = self.$_.find(self.chapters, item => item.key === chapterKey);
  return {
    id: chapter.id,
    key: chapter.key,
    chapter_sort: chapter.sort,
    name: chapter.name,
    Question: questions
  };
});
self.chapterWithRecord = self.$_.cloneDeep(chapterWithRecord);
      
this.getSubmission();
    },
    getSubmission() {
      this.submissionApi.url =
        this.$api.servers.log +
        "/api/v1/" +
        this.$_getLocale() +
        "/submission/player/" +
        this.mochiId;

      this.submissionApi.callbackReset = () => {
        this.submissionApi.isLoading = true;
        this.submissionApi.isError = false;
      };
      this.submissionApi.callbackError = (e) => {
        this.submissionApi.isLoading = false;
        this.submissionApi.isError = true;
        this.submissionApi.error = e;
        //BOC:[parent]
        this.callbackError(e);
        //EOC
      };
      this.submissionApi.callbackSuccess = (resp) => {
        this.submissionApi.isLoading = false;
        this.submission = resp.Submission;
        this.filterRecord();
        this.filterQuestion();
        this.filterSubmission();
      };
      this.submissionApi.params = { syllabusKey: this.syllabus.key };
      this.$api.fetch(this.submissionApi);
    },
    showQuestion(key) {
      if (this.chapterKey) {
        this.chapterKey = null;
      } else {
        this.chapterKey = key;
        this.isShowQuestion = true;
      }
    },
    showSubmission(key, index) {
      if (this.questionKey) {
        this.questionKey = null;
        this.questionIndex = null;
      } else {
        this.questionKey = key;
        this.questionIndex = index;
      }
    },
    filterRecord() {
      // for (var n = 0, m = this.data.length; n < m; n++) {
      //   for (var i = 0, j = this.data[n].Record.length; i < j; i++) {
      //     this.records.push(this.data[n].Record[i]);
      //   }
      // }
      var result = [];
      this.records.reduce(function (res, value) {
        if (!res[value.chapterKey]) {
          res[value.chapterKey] = {
            chapterkey: value.chapterKey,
            totalCorrect: 0,
            totalQuestion: 0,
          };
          result.push(res[value.chapterKey]);
        }
        // res[value.chapterKey].totalScore += value.topScore;
        // res[value.chapterKey].totalStar += value.topStar;
        // res[value.chapterKey].totalAttempt += value.attempt;
        res[value.chapterKey].totalCorrect += value.totalCorrect;
        res[value.chapterKey].totalQuestion += value.totalQuestion;
        return res;
      }, {});
      this.recordsByChapter = result;
      for (var a = 0, b = this.recordsByChapter.length; a < b; a++) {
        this.joinRecordWithChapter(
          this.recordsByChapter[a].chapterKey,
          this.recordsByChapter[a]
        );
      }
    },
    joinRecordWithChapter(key, record) {
      for (var p = 0, q = this.chapterWithRecord.length; p < q; p++) {
        if (record.chapterkey == this.chapterWithRecord[p].key) {
          this.chapterWithRecord[p].Record = record;
        }
      }
    },
    filterQuestion() {
      //var result = [];
      // this.records.reduce(function (res, value) {
      //   if (!res[value.questionKey]) {
      //     res[value.questionKey] = {
      //       questionKey: value.questionKey,
      //       totalQuestion: 0,
      //       totalCorrect: 0,
      //     };
      //     result.push(res[value.questionKey]);
      //   }
      //   res[value.questionKey].totalQuestion += value.totalQuestion;
      //   res[value.questionKey].totalCorrect += value.totalCorrect;
      //   return res;
      // }, {});
      this.recordsByQuestion = this.records;
      for (var k = 0, l = this.recordsByQuestion.length; k < l; k++) {
        this.joinRecordWithQuestion(
          this.recordsByQuestion[k].questionKey,
          this.recordsByQuestion[k]
        );
      }
    },
    joinRecordWithQuestion(key, record) {
      for (var p = 0, q = this.chapterWithRecord.length; p < q; p++) {
        for (
          var x = 0, y = this.chapterWithRecord[p].Question.length;
          x < y;
          x++
        ) {
          if (
            key == this.chapterWithRecord[p].Question[x].key
          ) {
            this.chapterWithRecord[p].Question[x].Record =
              record;
          }
        }
      }
      this.orderQuestion();
    },
    filterSubmission() {
      this.submission.sort((a, b) =>
        a.timestampCreated > b.timestampCreated ? 1 : -1
      );
      var result = [];
      this.submission.reduce(function (res, value) {
        if (!res[value.questionKey]) {
          res[value.questionKey] = {
            questionKey: value.questionKey,
            totalQuestion: 0,
            totalCountCorrect: 0,
            totalTime: 0,
          };
          result.push(res[value.questionKey]);
        }
        res[value.questionKey].totalCountCorrect += value.countCorrect;
        res[value.questionKey].totalQuestion += value.countQuestion;
        res[value.questionKey].totalTime += value.time;
        return res;
      }, {});
      this.submissionByQuestion = result;
      for (var p = 0, q = this.chapterWithRecord.length; p < q; p++) {
        for (
          var x = 0, y = this.chapterWithRecord[p].Question.length;
          x < y;
          x++
        ) {
          this.joinSubmissionWithQuestion(
            this.chapterWithRecord[p].Question[x]
          );
        }
      }
    },
    joinSubmissionWithQuestion(question) {
      for (var p = 0, q = this.submissionByQuestion.length; p < q; p++) {
        if (question.key == this.submissionByQuestion[p].questionKey) {
          question.Record.countCorrect =
            this.submissionByQuestion[p].totalCountCorrect;
          question.Record.countQuestion =
            this.submissionByQuestion[p].totalQuestion;
          question.Record.time =
            this.submissionByQuestion[p].totalTime;
        }
      }
      var submission = [];
      for (var a = 0, b = this.submission.length; a < b; a++) {
        if (this.submission[a].questionKey == question.key) {
          submission.push(this.submission[a]);
          submission.sort((a, b) =>
            a.timestampCreated < b.timestampCreated ? 1 : -1
          );
        }
      }
      question.Submission = submission;

      this.addAttemptToChapter();
    },
    orderQuestion() {
      if (this.submission.length == 0) {
        this.addAttemptToChapter();
      }
      for (var p = 0, q = this.chapterWithRecord.length; p < q; p++) {
        for (
          var x = 0, y = this.chapterWithRecord[p].Question.length;
          x < y;
          x++
        ) {
          this.chapterWithRecord[p].Question.sort((a, b) =>
            a.key > b.key ? 1 : -1
          );
        }
      }
    },
    addAttemptToChapter() {
      for (var a = 0, b = this.chapterWithRecord.length; a < b; a++) {
        var attempt = 0;
        var star = 0;
        for (
          var n = 0, m = this.chapterWithRecord[a].Question.length;
          n < m;
          n++
        ) {
          if (
            this.chapterWithRecord[a].Question[n].Submission
          ) {
            attempt =
              attempt +
              this.chapterWithRecord[a].Question[n].Submission
                .length;
          }
          if (this.chapterWithRecord[a].Question[n].Record) {
            star =
              star +
              this.countChapterStar(
                this.chapterWithRecord[a].Question[n].Record
                  .totalCorrect,
                this.chapterWithRecord[a].Question[n].Record
                  .totalQuestion
              );
          }
        }
        if (this.chapterWithRecord[a].Record) {
          this.chapterWithRecord[a].Record.attempt = attempt;
          this.chapterWithRecord[a].Record.star = star;
        }
      }
    },
    countChapterStar(countCorrect, countQuestion) {
      if ((countCorrect / countQuestion) * 100 < 60) {
        return 0;
      } else if (
        (countCorrect / countQuestion) * 100 >= 60 &&
        (countCorrect / countQuestion) * 100 < 80
      ) {
        return 1;
      } else if (
        (countCorrect / countQuestion) * 100 >= 80 &&
        (countCorrect / countQuestion) * 100 < 100
      ) {
        return 2;
      } else {
        return 3;
      }
    },
    getWeakestQuestions(resp){
      this.weakestQuestionsFilter = this.$_.filter(resp, itemA => {
  const itemB = this.$_.find(this.weakestQuestions, ['questionKey', itemA.key]);
  return itemB ? true : false;
}).map(itemA => {
  const itemB = this.$_.find(this.weakestQuestions, ['questionKey', itemA.key]);
  return {
    id: itemA.id,
    key: itemA.key,
    name: itemA.name,
    chapter_sort:itemA.chapter_sort,
    chapter_name: itemA.chapter_name,
    accuracy: itemB.accuracy
  };
});
    }
  },
};
</script>
<style>
#resp-table-header {
  display: table-header-group;
  background-color: gray;
  font-weight: bold;
  font-size: 25px;
}
.table-header-cell {
  display: table-cell;
  width: 140px !important;
  text-align: right !important;
  border-bottom: 1px solid black;
}
#resp-table-body {
  display: table-row-group;
}
.resp-table-row {
  display: table-row;
}
.table-body-cell-main {
  display: table-cell;
  width: 140px !important;
  text-align: right !important;
}

.question-name {
  display: block !important;
  width: 155px !important;
  word-wrap: break-word !important;
}
.submission-name {
  display: block !important;
  width: 145px !important;
  word-wrap: break-word !important;
}
.chapter-name {
  display: block !important;
  width: 170px;
  word-wrap: break-word !important;
}
.performance .v-list-item__icon {
  display: none;
}
</style>